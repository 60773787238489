  .rs-modal-open {
    z-index: 1149;
  }
  .rs-modal-open {
    overflow: hidden;
  }
  .rs-modal-wrapper {
    position: fixed;
    overflow: auto;
    z-index: 1050;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
  .rs-modal {
    display: none;
    overflow: visible;
    outline: 0;
    margin: 30px auto 0 auto;
    position: relative;
    width: auto;
    z-index: 1050;
  }
  .rs-modal-lg {
    width: 968px;
  }
  .rs-modal-md {
    width: 800px;
  }
  .rs-modal-sm {
    width: 600px;
  }
  .rs-modal-xs {
    width: 400px;
  }
  .rs-modal-full {
    width: calc(100% - 120px);
  }
  .rs-modal-full .rs-modal-dialog {
    margin-left: 60px;
    margin-right: 60px;
  }
  .rs-modal-content {
    position: relative;
    background-color: #fff;
    border-radius: 6px;
    outline: 0;
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
    padding: 20px;
  }
  .rs-modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #272c36;
  }
  .rs-modal-backdrop.fade {
    opacity: 0;
    -webkit-transition: opacity 0.3s ease-in;
    transition: opacity 0.3s ease-in;
  }
  .rs-modal-backdrop.in {
    opacity: 0.3;
  }
  .rs-modal-header {
    padding-right: 20px;
  }
  .rs-modal-header::before,
  .rs-modal-header::after {
    content: ' ';
    display: table;
  }
  .rs-modal-header::after {
    clear: both;
  }
  .rs-modal-header::before,
  .rs-modal-header::after {
    content: ' ';
    display: table;
  }
  .rs-modal-header::after {
    clear: both;
  }
  .rs-modal-header .rs-modal-title {
    font-size: 16px;
    line-height: 1.25;
    display: block;
    color: #575757;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
  /* .rs-modal-header .rs-modal-header-close {
    outline: 0;
    position: absolute;
    right: 15px;
    top: 10px;
    font-size: 22px;
    line-height: 1.66666667;
    color: #575757;
    width: 20px;
    padding: 0 4px;
    background: none;
    border-style: none;
  } */
  /* .rs-modal-header .rs-modal-header-close::before {
    font-family: 'rsuite-icon-font';
    font-style: normal;
    line-height: 1;
    content: '\f00d';
  }
  .rs-modal-header .rs-modal-header-close [aria-hidden] {
    display: none;
  } */
  .rs-modal-header .title {
    margin: 0;
    color: #3498ff;
    font-size: 16px;
    line-height: 1.375;
  }
  .rs-modal-body {
    position: relative;
    margin-top: 30px;
    padding-bottom: 30px;
  }
  .rs-modal-footer {
    text-align: right;
    border-top: none;
  }
  .rs-modal-footer::before,
  .rs-modal-footer::after {
    content: ' ';
    display: table;
  }
  .rs-modal-footer::after {
    clear: both;
  }
  .rs-modal-footer::before,
  .rs-modal-footer::after {
    content: ' ';
    display: table;
  }
  .rs-modal-footer::after {
    clear: both;
  }
  .rs-modal-footer .rs-btn + .rs-btn {
    margin-left: 10px;
    margin-bottom: 0;
  }
  .rs-modal-footer .rs-btn-group .rs-btn + .rs-btn {
    margin-left: -1px;
  }
  .rs-modal-footer .rs-btn-block + .rs-btn-block {
    margin-left: 0;
  }
  .rs-modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
  }

